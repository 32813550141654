import Vue from 'vue';

Vue.directive('lowercase', {
  bind(el, binding, vnode) {
    const toLowerCase = (e) => {
      e.target.value = String(e.target.value).toLowerCase();
      if(vnode.componentInstance) {
        vnode.componentInstance.$emit('input', e.target.value);
      }
    };

    el.addEventListener('input', toLowerCase);
  },

  update(el, binding, vnode) {
    const input = el as HTMLInputElement;

    if (input.value) {
      input.value = String(input.value).toLowerCase();
      vnode?.componentInstance?.$emit('input', input.value);
    }
  },

});