import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { RootState } from '..';
import type { ImageManagerState } from '~/types/store/imageEditorManager';

const state: ImageManagerState = {
  temporaryImages: new Map(),
  uploadedMinutaImages: new Map(),
  uploadedTemplateImages: new Map(),
  toDeleteImages: new Map(),
};

type EditorMode = 'template' | 'minuta'

const getters: GetterTree<ImageManagerState, RootState> = {
  obterImagensTemporarias: (state) => state.temporaryImages,
  obterImagensCarregadasMinuta: (state) => state.uploadedMinutaImages,
  obterImagensCarregadasTemplate: (state) => state.uploadedTemplateImages,
  obterImagensParaDeletar: (state) => state.toDeleteImages,
};

const mutations: MutationTree<ImageManagerState> = {
  setTemporaryImages(state, imagem: { key: string, url: string }) {
    const { key, url } = imagem;
    state.temporaryImages.set(key, url);
  },
  setUploadedTemplateImages(state, imagem: { key: string, url: string }) {
    const { key, url } = imagem;
    state.uploadedTemplateImages.set(key, url);
  },
  setUploadedMinutaImages(state, imagem: { key: string, url: string }) {
    const { key, url } = imagem;
    state.uploadedMinutaImages.set(key, url);
  },
  setToDeleteImages(state, imagem: { key: string, url: string }) {
    const { key, url } = imagem;
    state.toDeleteImages.set(key, url); 
  },
  deleteTemporaryImage(state, key: string) {
    state.temporaryImages.delete(key);
  },
  deleteUploadedTemplateImage(state, key: string) {
    state.uploadedTemplateImages.delete(key);
  },
  deleteToDeleteImage(state, key: string) {
    state.toDeleteImages.delete(key);
  },
  resetState(state) {
    state.temporaryImages.clear();
    state.uploadedMinutaImages.clear();
    state.uploadedTemplateImages.clear();
    state.toDeleteImages.clear();
  }
};


const actions: ActionTree<ImageManagerState, RootState> = {
  resetarDados({ commit }) {
    // Limpa todos os mapas da store
    commit('resetState');
  },
  lidarComImagens({ state, commit }, doc: { imagens: HTMLCollectionOf<HTMLImageElement>, editorMode:EditorMode } ) {
    const { imagens, editorMode } = doc

    const extractIdFromBlobUrl = (blobUrl: string) => {
      return blobUrl.split('/').pop() || '';
    };

    const imagensNoEditor = new Map<string, string>();

    for (const element of imagens) {
      const src = element.src;
      const mode = element.getAttribute('data-image-mode') as EditorMode;

      if (!src) continue;

      const uploadedImagesMap = mode === 'minuta' ? state.uploadedMinutaImages  : state.uploadedTemplateImages;
      const uploadedMutation = mode === 'minuta' ? 'setUploadedMinutaImages' : 'setUploadedTemplateImages';
      
      // Identifica se a imagem é um blob temporário
      if (src.startsWith('blob:')) {
        const blobId = extractIdFromBlobUrl(src);
        if (!state.temporaryImages.has(blobId)) {
          commit('setTemporaryImages', { key: blobId, url: src });
        }
        imagensNoEditor.set(blobId, src);
      } else {
        // Caso seja uma imagem já carregada no servidor
        if (!uploadedImagesMap.has(src)) {
          commit(uploadedMutation, { key: src, url: src });
        }
        imagensNoEditor.set(src, src);

        if (state.toDeleteImages.has(src)) {
          commit('deleteToDeleteImage', src);
        }
      }
    }

    // Remove imagens temporárias que não estão mais no editor
    for (const [key] of state.temporaryImages) {
      if (!imagensNoEditor.has(key)) {
        commit('deleteTemporaryImage', key);
      }
    }

    // Remove imagens carregadas que não estão mais no editor
    const uploadedImagesEditorMap = editorMode === 'minuta' ? state.uploadedMinutaImages  : state.uploadedTemplateImages;
    for (const [key] of uploadedImagesEditorMap) {
      if (!imagensNoEditor.has(key)) {
        commit('setToDeleteImages', { key, url: key });
      }
    }
    
    // Remove imagens carregadas do template que não estão mais no editor e não será mais copiada para a minuta
    if (editorMode === 'minuta') {
      
      for (const [key] of state.uploadedTemplateImages) {
        if (!imagensNoEditor.has(key)) {
          commit('deleteUploadedTemplateImage', key);
        }
      }
    }
  },
};



export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
