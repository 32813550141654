import axios from 'axios'
import baseService from '../baseServise'
import { IClienteModel } from '../../models/cliente'
import { IApiOKResult } from '../../types/api'

class ServiceCliente extends baseService<IClienteModel> {
  constructor() {
    super('Cliente')
  }

  /** 
   * @return IClienteSimplificadoModel 
   * */
  override async getAll() {
    return axios.get(`${this.baseURL}`).then(({ data }) => ({ data, success: true }))
  }

  async buscaCep(cep: string) {
    return (await axios.get(`${this.baseURL}/consultaCep?cep=${cep}`)).data
  }

  async buscaCliente(
    clienteId: string | number
  ): Promise<IApiOKResult<IClienteModel>> {
    return (await axios.get(`${this.baseURL}/GetClienteId?id=${clienteId}`))
      .data
  }

  async obterTodosClientes(
    idEmpresa: number
  ): Promise<IApiOKResult<Array<IClienteModel>>> {
    return (
      await axios.get(
        `${this.baseURL}/GetClientesEmpresa?idempresa=${idEmpresa}`
      )
    ).data
  }

  async atualizarCliente(
    clienteId: number | string,
    atualizarTitulos: boolean,
    cliente: IClienteModel
  ): Promise<IApiOKResult<IClienteModel>> {
    return (
      await axios.put(
        `${this.baseURL}/UpdateCliente?id=${clienteId}&atualizarTitulos=${atualizarTitulos}`,
        cliente
      )
    ).data
  }

  async adicionarCliente(
    cliente: IClienteModel
  ): Promise<IApiOKResult<IClienteModel>> {
    return (await axios.post(`${this.baseURL}/AddCliente`, cliente)).data
  }

  async delteteArquivoById(
    arquivoId: number | string
  ): Promise<IApiOKResult<boolean>> {
    return (await axios.delete(`${this.baseURL}/arquivo/${arquivoId}`)).data
  }
}

export default new ServiceCliente()
