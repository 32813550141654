import Blank from '~/layouts/blank.vue';

export default {
  path: '/emitentes',
  component: Blank,
  children: [
    {
      path: '',
      component: () => import('./index.vue'),
      name: 'emitentes',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Emitentes'
      },
    },
  ],
};
