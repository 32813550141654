/* eslint-disable @typescript-eslint/no-explicit-any */
import { validarCNPJ } from './validations/cnpj';
import { validateCPF } from './validations/cpf';
import { validarZero } from './validations/zero';
import { validarData } from './validations/data';
import { retirarFormatacaoNumero } from './formats';
import { dataFormatada } from './formats/data';


interface ITrigger { trigger?: Array<'blur' | 'change'>}
interface IBetween extends ITrigger { min: number; max: number }
interface IRuleDate extends ITrigger { date: { day: number } }
interface IRuleMax extends ITrigger { max: number }
interface IRuleAsyncValidate extends ITrigger {
  asyncValidate: (value)=> Promise<{ success: boolean, message: string }>
}
interface IRuleValidate extends ITrigger {
  validate: (value)=>  { success: boolean, message: string }
}

/** 
 * Definição de tipo para aceitar tanto strings quanto objetos com parâmetros extras 
*/
export type ValidationRule =
  | 'required'
  | 'numberRequired'
  | 'dataPassada'
  | 'email'
  | 'cpf'
  | 'cnpj'
  | 'zero'
  | { between: IBetween }
  | { numberBetween: IBetween }
  | IRuleMax
  | IRuleDate
  | IRuleAsyncValidate
  | IRuleValidate
;

interface FormRule {
  trigger?: string | string[];
  required?: boolean;
  message?: string;
  type?: string;
  validator?: (rule: any, value: any, callback: (error?: Error) => void) => void;
  min?: number;
  max?: number;
}

export const validationForm = (rules: ValidationRule[]): FormRule[] => {
  const formRules: FormRule[] = [];

  rules.forEach((rule) => {
    const ruleKey = typeof rule === 'string' ? rule : Object.keys(rule)[0];
    switch (ruleKey) {
      case 'required':
        formRules.push({
          trigger: ['blur', 'change'],
          required: true,
          message: 'Campo obrigatório!'
        });
        break;

      case 'numberRequired':
        formRules.push({
          trigger: ['blur', 'change'],
          required: true,
          type: 'number',
          message: 'Campo obrigatório!',
        });
        break;

      case 'dataPassada':
        formRules.push({
          trigger: ['blur', 'change'],
          validator: (r, value, callback) =>
            !validarData(value).isValid
              ? callback(new Error('Não é possível definir uma data retroativa!'))
              : callback(),
        });
        break;

      case 'date':
        const { date } = rule as IRuleDate
        formRules.push({
          trigger: ['blur', 'change'],
          validator: (r, value, callback) => {
            if (!value) return callback();
            const validation = validarData(value, date.day)
            if (!validation.isValid) {
              callback(new Error(`A data informada deve ser maior que ${dataFormatada(validation.proximaDataDisponivel)} (D + ${date.day}).`));
            } else {
              callback();
            }
          },
        });
        break;

      case 'email':
        formRules.push({
          trigger: ['blur', 'change'],
          required: false,
          type: 'email',
          message: 'Digite um email válido!',
        });
        break;

      case 'between':
        const { between } = rule as { between: IBetween }
        formRules.push({
          trigger: ['blur', 'change'],
          min: between.min,
          max: between.max,
          message: `Tamanho permitido entre ${between.min} a ${between.max} caracteres`,
        });
        break;

      case 'numberBetween':
        const { numberBetween } = rule as { numberBetween: IBetween }
        formRules.push({
          trigger: ['blur', 'change'],
          validator: (r, value, callback) => {
            if (!value) return callback();
            const numberValue = retirarFormatacaoNumero(value);
            if (
              isNaN(numberValue) ||
              numberValue < numberBetween.min ||
              numberValue > numberBetween.max
            ) {
              callback(new Error(`Valor permitido entre ${numberBetween.min} e ${numberBetween.max}`));
            } else {
              callback();
            }
          },
        });
        break;

      case 'cpf':
        formRules.push({
          validator: (r, value, callback) =>
            value
              ? validateCPF(value)
                ? callback()
                : callback(new Error('CPF inválido!'))
              : callback(),
        });
        break;

      case 'cnpj':
        formRules.push({
          validator: (r, value, callback) =>
            value
              ? validarCNPJ(value)
                ? callback()
                : callback(new Error('CNPJ inválido!'))
              : callback(),
        });
        break;

      case 'max':
        const { max } = rule as IRuleMax
        formRules.push({
          validator: (r, value, callback) => {
            const valueLength = value?.length ?? 0;
            valueLength <= max
              ? callback()
              : callback(new Error(`Tamanho máximo permitido de ${max} caracteres`));
          },
        });
        break;

      case 'zero':
        formRules.push({
          validator: (r, value, callback) =>
            validarZero(value)
              ? callback(new Error('O número não pode ser zero.'))
              : callback(),
        });
        break;

      case 'asyncValidate':
        const { asyncValidate, trigger } = rule as IRuleAsyncValidate;
        formRules.push({
          validator: async (r: any, value: string, callback: (arg?: Error) => void) => {
            if (!value) {
              callback();
              return;
            }
            if (asyncValidate) {
              const validation = await asyncValidate(value);
              if (!validation.success) {
                callback(new Error(validation.message));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: trigger ? trigger : ['blur', 'change']
        });
        break;

      case 'validate':
        const { validate, trigger: triggerValidate } = rule as IRuleValidate;
        formRules.push({
          validator: (r: any, value: string, callback: (arg?: Error) => void) => {
            if (validate) {
              const validation =  validate(value);
              
              if (!validation.success) {
                callback(new Error(validation.message));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: triggerValidate ? triggerValidate : ['blur', 'change']
        });
        break;
      
    }
  });

  return formRules;
};
