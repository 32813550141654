import type { ICondicaoEntregaModel } from '~/models/condicaoEntrega';
import type { ICprFinanceiraModel } from '~/models/cprFinanceira';
import type { IDadosCprModel } from '~/models/dadosCpr';
import { IMinutaCommandModel } from '~/models/minuta';

export enum StatusCampos {
  NAO_SE_APLICA,
  PENDENTES,
  REVISADOS
}

export interface IErrorField<T> {
  campo: keyof T;
  mensagem: string;
}

export interface IFormErrors {
  dadosCpr: IErrorField<IDadosCprModel>[];
  dadosFinanceiros: IErrorField<ICprFinanceiraModel>[];
  formacoesLavoura: IErrorField<any>[];
  condicaoEntrega: IErrorField<ICondicaoEntregaModel>[];
  documentos: IErrorField<object>[]
  garantias: IErrorField<object>[]
  minuta: IErrorField<IMinutaCommandModel>[]
}

export interface IFormErrorsState {
  formsErrors: IFormErrors;
  camposPendentesRevisao: StatusCampos
}
