import Vue from 'vue';
import Vuex from 'vuex';
import auth, { type AuthState } from './modules/auth';
import parametrizacoes, { type ParametrizacaoState } from './modules/parametrizacao';
import b3, { type B3State } from './modules/b3';
import formErrors from './modules/form-errors';
import imageEditorManager from './modules/imageEditorManager';
import type { ImageManagerState } from '~/types/store/imageEditorManager';
import type { IFormErrorsState } from '~/types/store/form-errors';

export interface RootState {
  auth: AuthState;
  parametrizacoes: ParametrizacaoState
  b3: B3State
  formErrors: IFormErrorsState
  imageEditorManager: ImageManagerState
}

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    auth,
    parametrizacoes,
    b3,
    formErrors,
    imageEditorManager
  },
  strict: process.env.NODE_ENV !== 'production',
});
