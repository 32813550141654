<template>
  <button
    type="button"
    :class="[
      'cpr-btn',
      `cpr-btn-${type}`,
      `${size}`,
      { 'is-disabled': disabled },
      { 'dashed': !dashed },
      { 'is-danger': !danger },
      { 'circle': !circle }
    ]"
    @click="(e) => $emit('click', e)"
  >
    <cpr-icon :icon-name="prefixIcon" v-if="prefixIcon" />
    <slot />
    <cpr-icon :icon-name="suffixIcon" v-if="suffixIcon" />
    <i v-if="loading" class="el-icon-loading spin" />
  </button>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CprIcon from '~/components/CprIcon.vue'

type ButtonType = 'primary' | 'secondary' | 'tertiary'
type ButtonSyze = 'default' | 'medium' | 'small' | 'mine'

/** @todo Alterar cores primárias e secundárias  */
@Component({
  name: 'cpr-button',
  components: {
    CprIcon
  }
})
export default class CprButton extends Vue {
  @Prop({ default: '' }) prefixIcon!: string
  @Prop({ default: '' }) suffixIcon!: string
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: true }) dashed!: boolean
  @Prop({ default: true }) danger!: boolean
  @Prop({ default: true }) circle!: boolean
  @Prop({ default: 'primary' }) type!: ButtonType
  @Prop({ default: 'default' }) size!: ButtonSyze
}
</script>

<style lang="scss" scoped>
.cpr-btn {
  border-radius: 8px;
  padding: 13px 16px 13px 16px;
  height: 48px;
  min-width: 50px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  line-height: 24px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid get-color(primary, low-medium);
  appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  box-shadow: 0px 1px 2px rgba(66, 59, 59, 0.32),
    0px 1px 4px 1px rgba(0, 0, 0, 0.16);

  &.mini {
    height: 32px;
    min-width: 32px;
    padding: 6px;

    > svg {
      height: 24px !important;
      width: 24px !important;
    }
  }

  &.small {
    height: 40px;
    min-width: 40px;
    padding: 8px;
  }

  > svg {
    height: 24px !important;
    width: 24px !important;;
  }

  &.is-disabled,
  &.is-danger.is-disabled {
    &.cpr-btn-primary,
    &.cpr-btn-secondary,
    &.cpr-btn-tertiary {
      background-color: get-color(neutral, high-medium);
      color: get-color(neutral, high-dark);
      border-color: #BEBEBE;
      box-shadow: none;
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        background-color: get-color(neutral, high-medium);
        color: get-color(neutral, high-dark);
        border-color: #BEBEBE;
        box-shadow: none;
        cursor: not-allowed;
      }

      &:focus {
        background-color: get-color(neutral, high-medium);
        color: get-color(neutral, high-dark);
        border-color: #BEBEBE;
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }

  &-primary {
    background-color:get-color(primary, low-medium);
    border-color: get-color(primary, low-medium);
    color: get-color(neutral, ultra-light);

    &:hover {
      color: #FFF;
      background: get-color(primary, low-light);
      border-color: get-color(primary, low-light);
    }
  }

  &-secondary {
    background-color: get-color(neutral, ultra-light);
    border-color: get-color(primary, low-medium);
    color: get-color(primary, low-medium);

    &:hover {
      background: get-color(primary, high-light);
      border-color: get-color(primary, low-medium);
      color: get-color(primary, low-medium);
    }

    &:focus {
      background: get-color(primary, high-light);
      border: 2px solid get-color(primary, high-medium);
      color: get-color(primary, low-medium);
    }
  }

  &.dashed {
    border-style: dashed;
    border-width: 1px;
    box-shadow: none;

    &:hover {
      border: 1px dashed;
    }
  }

  &.circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  &-tertiary {
    border: none;
    color: get-color(primary, low-medium);
    box-shadow: none;
    background: transparent;

    &:hover{
      background: get-color(primary, ultra-light);
    }
  }

  &.is-danger {
    border-color: get-color(error, low-light);
    color: get-color(error, low-light);
    &.cpr-btn-primary {
      background-color: get-color(error, low-light);
      color: #fff;

      &:hover {
        background-color: get-color(error, high-medium);
        color: get-color(error, low-light);
      }
    }

    &.cpr-btn-secondary {
      border-color: get-color(error, low-light);
      background-color: get-color(error, high-medium);
      color: get-color(error, low-light);

      &:hover {
        background-color: get-color(error, high-dark);
      }
    }

    &.cpr-btn-tertiary {
      border: none;
      background-color: transparent;
      &:hover {
        background-color: get-color(error, high-medium);
      }
    }
  }

  .spin {
    margin-left: 1cqmax;
    animation: spin 1.3s infinite linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
