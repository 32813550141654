<script>
import * as service from './services';
import IconTeam from '../../../assets/svgs/icon-team.svg';
import IconArrow from '../../../assets/svgs/icon-arrow-right.svg';
import IconSearch from '../../../assets/svgs/icon-search.svg';

export default {
  name:'list-harvest',
  components: {
    IconTeam,
    IconArrow,
    IconSearch,
  },

  data: () => ({
    show: false,
    harvests: [],
    search: '',
    page: 1,
    loading: true,
  }),

  async mounted() {
    this.loading = true;
    this.harvests = await service.getAll();
    this.harvests = this.harvests.map((item) => ({
      ...item,
      status: item.status === 'A' ? 'Ativo' : 'Inativo',
    }));
    this.loading = false;
  },

  computed: {
    head() {
      return [
        {
          prop: 'descricao',
          label: 'Nome',
          sortable: true,
        },
        {
          prop: 'status',
          label: 'Status',
          width: 150,
          sortable: true,
        },
      ];
    },

    filtered() {
      const { harvests, search } = this;

      const lowered = search.toLowerCase();

      const items = harvests?.filter(
        ({ descricao }) => descricao && descricao.toLowerCase().indexOf(lowered) !== -1,
      );

      if (!search) return harvests;

      return items;
    },
  },

  methods: {
    toggle() {
      this.search = '';
      this.show = !this.show;
    },

    handleClick({ safraId }) {
      this.$router.push({
        params: { id: safraId },
        name: 'EditHarvest',
      });
    },

    handlePage(page) {
      this.page = page;
    },

    handleGoPage(value) {
      const newPage = Number(value);

      const rest = this.harvests.length % 20 === 0 ? 0 : 1;

      const totalPages = Math.trunc(this.harvests.length / 20) + rest;

      if (newPage < 1) {
        this.page = 1;

        return;
      }

      if (newPage > totalPages) {
        this.page = totalPages;

        return;
      }

      this.page = newPage;
    },
  },
};
</script>

<template>
  <div class="team">
    <div class="team__head">
      <button
        class="icon-button is-filled"
        v-if="!show"
        @click.prevent="toggle"
      >
        <IconSearch />
      </button>

      <el-input v-model="search" v-else placeholder="Buscar">
        <template #suffix>
          <i
            class="el-input__icon el-icon-close"
            style="cursor: pointer"
            @click.prevent="toggle"
          />
        </template>
      </el-input>

      <el-button type="primary" @click="$router.push({ name: 'NewHarvest' })"
        >Nova safra</el-button
      >
    </div>

    <div class="team__body">
      <div class="team__body-empty" v-if="loading">
        <span>
          <IconTeam />
        </span>
        <h3>Aguarde...</h3>
        <p class="p1">Carregando as safras</p>
      </div>

      <div class="table" v-else>
        <el-table
          @row-click="handleClick"
          header-cell-class-name="team__header"
          :data="filtered.slice((page - 1) * 20, page * 20)"
        >
          <el-table-column
            v-for="item in head"
            :key="item.label"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
          />
          <el-table-column align="left" width="56">
            <IconArrow />
          </el-table-column>

          <template #empty>
            <div class="team__body-empty">
              <span>
                <IconSearch />
              </span>
              <h3>Nenhuma safra encontrada</h3>
              <p class="p1">
                Confira a grafia e busque outra vez, ou realize um novo
                cadastro.
              </p>
            </div>
          </template>
        </el-table>

        <div v-if="harvests.length > 20" class="team__table-footer">
          <el-pagination
            :page-size="20"
            :current-page="page"
            hide-on-single-page
            :total="harvests.length"
            layout="prev, pager, next, jumper"
            @current-change="handlePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.team {
  &__head {
    width: fit-content;
    display: flex;
    margin-left: auto;

    button {
      margin-left: 24px;
    }

    .el-input {
      min-width: 316px;
    }
  }

  &__body {
    margin-top: 32px;

    &-empty {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
      background-color: $--color-gray-1;
      padding: 64px;
      align-items: center;

      span {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 16px;
        justify-content: center;
        background-color: rgba($--color-info, 0.2);

        svg path {
          fill: $--color-info;
        }
      }

      h3 {
        color: $--color-gray-7;
      }

      & p {
        margin-top: 8px;
        color: $--color-gray-6;
        max-width: 272px;
      }
    }
  }

  &__table-footer {
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1080px) {
  .team {
    padding: 0px 20px;
    box-sizing: border-box;
  }
}
</style>
