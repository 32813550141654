var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'cpr-btn',
    ("cpr-btn-" + _vm.type),
    ("" + _vm.size),
    { 'is-disabled': _vm.disabled },
    { 'dashed': !_vm.dashed },
    { 'is-danger': !_vm.danger },
    { 'circle': !_vm.circle }
  ],attrs:{"type":"button"},on:{"click":function (e) { return _vm.$emit('click', e); }}},[(_vm.prefixIcon)?_c('cpr-icon',{attrs:{"icon-name":_vm.prefixIcon}}):_vm._e(),_vm._t("default"),(_vm.suffixIcon)?_c('cpr-icon',{attrs:{"icon-name":_vm.suffixIcon}}):_vm._e(),(_vm.loading)?_c('i',{staticClass:"el-icon-loading spin"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }