<script>
import ServiceProdutoEmpresa from '../../../services/ProdutoEmpresa';
import IconTeam from '../../../assets/svgs/icon-team.svg';
import IconSearch from '../../../assets/svgs/icon-search.svg';

export default {
  name:'list-products',
  components: {
    IconTeam,
    IconSearch,
  },

  data: () => ({
    show: false,
    products: [],
    search: '',
    page: 1,
  }),

  async mounted() {
    this.products = (await ServiceProdutoEmpresa.listProdutoEmpresa()).data
    this.products = this.products.map((item) => ({
      produtoEmpresaId: item.produtoEmpresaId,
      descricao: item.produto.descricao,
      status: item.status,
      empresaId: item.empresaId,
      produtoId: item.produtoId,
    }));
  },

  computed: {
    head() {
      return [
        {
          prop: 'descricao',
          label: 'Nome',
          sortable: true,
        },
      ];
    },

    filtered() {
      const { products, search } = this;

      const lowered = search.toLowerCase();

      const items = products?.filter(
        ({ descricao }) => descricao && descricao.toLowerCase().indexOf(lowered) !== -1,
      );

      if (!search) return products;

      return items;
    },
  },

  methods: {
    toggle() {
      this.search = '';
      this.show = !this.show;
    },

    async handleProdutoEmpresa(produto){
      try {
        await ServiceProdutoEmpresa.save(produto, produto.produtoEmpresaId)
        this.$showNotify('success', `${produto.descricao} foi ${produto.status === 'A' ? 'Ativo' : 'Inativo'}`)
      } catch (error) {
        this.$showMessagesErrors(error);
      }
    },

    handlePage(page) {
      this.page = page;
    },

    handleGoPage(value) {
      const newPage = Number(value);

      const rest = this.products.length % 10 === 0 ? 0 : 1;

      const totalPages = Math.trunc(this.products.length / 10) + rest;

      if (newPage < 1) {
        this.page = 1;

        return;
      }

      if (newPage > totalPages) {
        this.page = totalPages;

        return;
      }

      this.page = newPage;
    },
  },
};
</script>

<template>
  <div class="team">
    <div class="team__head">
      <button
        class="icon-button is-filled"
        v-if="!show"
        @click.prevent="toggle"
      >
        <IconSearch />
      </button>

      <el-input v-model="search" v-else placeholder="Buscar">
        <template #suffix>
          <i
            class="el-input__icon el-icon-close"
            style="cursor: pointer"
            @click.prevent="toggle"
          />
        </template>
      </el-input>
    </div>

    <div class="team__body">
      <div class="team__body-empty" v-if="products.length <= 0">
        <span>
          <IconTeam />
        </span>
        <h3>Ops...</h3>
        <p class="p1">Não há culturas para carregar.</p>
      </div>

      <div class="table" v-else>
        <el-table
          header-cell-class-name="team__header"
          :data="filtered.slice((page - 1) * 10, page * 10)"
        >
          <el-table-column
            v-for="item in head"
            :key="item.label"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
          />
          <el-table-column 
            label="Status"
            align="center"
            prop="status"
            :width="150"
            :sortable="true"
          >
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="A"
                inactive-value="I"
                @change="handleProdutoEmpresa(scope.row)"
              />
            </template>
          </el-table-column>

          <template #empty>
            <div class="team__body-empty">
              <span>
                <IconSearch />
              </span>
              <h3>Nenhum produto encontrado</h3>
              <p class="p1">
                Confira a grafia e busque outra vez, ou realize um novo
                cadastro.
              </p>
            </div>
          </template>
        </el-table>

        <div v-if="products.length > 10" class="team__table-footer">
          <el-pagination
            :page-size="10"
            :current-page="page"
            hide-on-single-page
            :total="products.length"
            layout="prev, pager, next, jumper"
            @current-change="handlePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.team {
  &__head {
    width: fit-content;
    display: flex;
    margin-left: auto;

    button {
      margin-left: 24px;
    }

    .el-input {
      min-width: 316px;
    }
  }

  &__body {
    margin-top: 32px;

    &-empty {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
      background-color: $--color-gray-1;
      padding: 64px;
      align-items: center;

      span {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 16px;
        justify-content: center;
        background-color: rgba($--color-info, 0.2);

        svg path {
          fill: $--color-info;
        }
      }

      h3 {
        color: $--color-gray-7;
      }

      & p {
        margin-top: 8px;
        color: $--color-gray-6;
        max-width: 272px;
      }
    }
  }

  &__table-footer {
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1080px) {
  .team {
    padding: 0px 20px;
    box-sizing: border-box;
  }
}
</style>
