export enum TipoPagamento {
  JUROS_PRINCIPAL_VENCIMENTO = 1,         
  PERIODICO_JUROS_PRINCIPAL,          
  JUROS_AMORTIZACAO_PERIODICOS,       
  AMORTIZACAO_PERIODICA_JUROS_VENCIMENTO,
  PRINCIPAL_VENCIMENTO_SEM_JUROS,    
  AMORTIZACAO_SEM_JUROS,             
  /** Disponível apenas para CPR de produto na B3. Na CERC é aceito para ambos registros */
  PARCELADO,                         
  LIQUIDACAO_FORA_AMBITO_B3          
}

export const TipoPagamentoDictionary: Record<TipoPagamento, string> = {
  [TipoPagamento.JUROS_PRINCIPAL_VENCIMENTO]: 'Pagamento de juros e principal no vencimento',
  [TipoPagamento.PERIODICO_JUROS_PRINCIPAL]: 'Pagamento periódico de juros e principal no vencimento',
  [TipoPagamento.JUROS_AMORTIZACAO_PERIODICOS]: 'Pagamento de juros e amortização periódicos',
  [TipoPagamento.AMORTIZACAO_PERIODICA_JUROS_VENCIMENTO]: 'Pagamento de amortização periódica e juros no vencimento',
  [TipoPagamento.PRINCIPAL_VENCIMENTO_SEM_JUROS]: 'Pagamento de Principal no vencimento sem taxa de juros',
  [TipoPagamento.AMORTIZACAO_SEM_JUROS]: 'Pagamento de Amortização sem taxa de juros',
  [TipoPagamento.PARCELADO]: 'Parcelado',
  [TipoPagamento.LIQUIDACAO_FORA_AMBITO_B3]: 'Liquidação fora do âmbito B3',
};

export enum TipoIndexadores {
  VCP,
  DI,
  PREFIXADA,
  IGP_M,
  IPCA,
  DOLAR_FECHAMENTO_PTAX_VENDA,
  EURO_FECHAMENTO_VENDA,
  /** Disponível apenas registro CERC */
  SELIC,
  /** Disponível apenas registro CERC */
  INCC_M,
  /** Disponível apenas registro CERC */
  INCC_DI,
  /** Disponível apenas registro CERC */
  OUTROS,
  /** Disponível apenas registro CERC */
  VARIACAO_CAMBIAL
}

export const TipoIndexadoresDictionary: Record<TipoIndexadores, string> = {
  [TipoIndexadores.VCP]: 'VCP',
  [TipoIndexadores.DI]: 'DI',
  [TipoIndexadores.PREFIXADA]: 'Prefixada',
  [TipoIndexadores.IGP_M]: 'IGP-M',
  [TipoIndexadores.IPCA]: 'IPCA',
  [TipoIndexadores.DOLAR_FECHAMENTO_PTAX_VENDA]: 'Dólar Fechamento PTAX Venda',
  [TipoIndexadores.EURO_FECHAMENTO_VENDA]: 'Euro Fechamento Venda',
  [TipoIndexadores.SELIC]: 'SELIC',
  [TipoIndexadores.INCC_M]: 'INCC-M',
  [TipoIndexadores.INCC_DI]: 'INCC-DI',
  [TipoIndexadores.OUTROS]: 'OUTROS',
  [TipoIndexadores.VARIACAO_CAMBIAL]: 'Variação cambial',
};

export enum TipoCalculoTaxaJuros {
  DIAS_UTEIS = 1,
  MENSAL_DIAS_UTEIS,
  DIARIO_DIAS_CORRIDOS_COMERCIAL,
  MENSAL_DIAS_CORRIDOS_COMERCIAL,
  DIARIO_DIAS_CORRIDOS_CIVIL,
  MENSAL_DIAS_CORRIDOS_CIVIL,
}

export const TipoCalculoTaxaJurosDictionary: Record<TipoCalculoTaxaJuros, string> = {
  [TipoCalculoTaxaJuros.DIAS_UTEIS]: 'Diário dias úteis',
  [TipoCalculoTaxaJuros.MENSAL_DIAS_UTEIS]: 'Mensal dias úteis',
  [TipoCalculoTaxaJuros.DIARIO_DIAS_CORRIDOS_COMERCIAL]: 'Diário dias corridos comercial',
  [TipoCalculoTaxaJuros.MENSAL_DIAS_CORRIDOS_COMERCIAL]: 'Mensal dias corridos comercial',
  [TipoCalculoTaxaJuros.DIARIO_DIAS_CORRIDOS_CIVIL]: 'Diário dias corridos civil',
  [TipoCalculoTaxaJuros.MENSAL_DIAS_CORRIDOS_CIVIL]: 'Mensal dias corridos civil',
};

export enum ModadelidadeLiquidacacaoEnum {
  /** Fora do âmbito B3 */
  SEM_MODALIDADE,
  /** */
  CETIP,
  /** Liquidação na B3 e esteja sendo já depositada neste momento para uma conta de outro participante diferente do Registrado */
  BRUTA
}

export const ModadelidadeLiquidacacaoDictionary: Record<ModadelidadeLiquidacacaoEnum, string> = {
  [ModadelidadeLiquidacacaoEnum.SEM_MODALIDADE]: 'Sem modalidade',
  [ModadelidadeLiquidacacaoEnum.CETIP]: 'CETIP',
  [ModadelidadeLiquidacacaoEnum.BRUTA]: 'Bruta',
};

export enum TipoContaB3 {
  PROPRIA,
  TERCEIRA
}

export const TipoContaB3Dictionary: Record<TipoContaB3, string> = {
  [TipoContaB3.PROPRIA]: 'Própria',
  [TipoContaB3.TERCEIRA]: 'Terceira',
};

export enum TipoAmortizacao {
  PERCENTUAL_FIXO_VALOR_UNITARIO = 1,
  PERCENTUAL_VARIAVEL_VALOR_UNITARIO = 2,
  PERCENTUAL_VARIAVEL_VALOR_REMANESCENTE = 3,
  PERCENTUAL_VARIAVEL_PERIODO_VARIAVEL_VALOR_UNITARIO = 4,
  PERCENTUAL_VARIAVEL_PERIODO_VARIAVEL_VALOR_REMANESCENTE = 5,
}

export const TipoAmortizacaoDictionary: Record<TipoAmortizacao, string> = {
  [TipoAmortizacao.PERCENTUAL_FIXO_VALOR_UNITARIO]:
    'Percentual fixo, períodos uniformes, sobre valor unitário de emissão',
  [TipoAmortizacao.PERCENTUAL_VARIAVEL_VALOR_UNITARIO]:
    'Percentual variável, períodos uniformes, sobre valor unitário de emissão',
  [TipoAmortizacao.PERCENTUAL_VARIAVEL_VALOR_REMANESCENTE]:
    'Percentual variável, períodos uniformes, sobre valor remanescente',
  [TipoAmortizacao.PERCENTUAL_VARIAVEL_PERIODO_VARIAVEL_VALOR_UNITARIO]:
    'Percentual variável, períodos variáveis (datas imputadas), sobre valor unitário de emissão',
  [TipoAmortizacao.PERCENTUAL_VARIAVEL_PERIODO_VARIAVEL_VALOR_REMANESCENTE]:
    'Percentual variável, períodos variáveis (datas imputadas), sobre valor remanescente'
}

export enum IntervaloTempoEnum {
  DIARIO,
  MENSAL,
  FINAL,
}

export const IntervaloTempoDictionary: Record<IntervaloTempoEnum, string> = {
  [IntervaloTempoEnum.DIARIO]: 'Diário',
  [IntervaloTempoEnum.MENSAL]: 'Mensal',
  [IntervaloTempoEnum.FINAL]: 'Final',
};

export enum PrazoEnum {
  UTIL,
  CORRIDO,
}

export const PrazoDictionary: Record<PrazoEnum, string> = {
  [PrazoEnum.UTIL]: 'Dia útil',
  [PrazoEnum.CORRIDO]: 'Dia corrido',
};

export enum TipoInformativo  {
  SIM,
  NAO,
  MULTIPLO
}

export const TipoInformativoDictionary: Record<TipoInformativo , string> = {
  [TipoInformativo.SIM]: 'Informado',
  [TipoInformativo.NAO]: 'Não informado',
  [TipoInformativo.MULTIPLO]: 'Informado em múltiplas IPOCs',
};

export enum FinalidadeCreditoEnum  {
  CAPITAL_DE_GIRO = '6172',
  INDUSTRIALIZACAO = '6098',
  CUSTEIO = '6096',
  INVESTIMENTO = '8623',
  COMERCIALIZACAO = '6120',
  NAO_SE_APLICA = '6099',
}

export const FinalidadeCreditoDictionary: Record<FinalidadeCreditoEnum, string> = {
  [FinalidadeCreditoEnum.CAPITAL_DE_GIRO]: 'Capital de Giro',
  [FinalidadeCreditoEnum.INDUSTRIALIZACAO]: 'Industrialização',
  [FinalidadeCreditoEnum.CUSTEIO]: 'Custeio',
  [FinalidadeCreditoEnum.INVESTIMENTO]: 'Investimento',
  [FinalidadeCreditoEnum.COMERCIALIZACAO]: 'Comercialização',
  [FinalidadeCreditoEnum.NAO_SE_APLICA]: 'Não se aplica',
};

export enum TipoLastro  {
  LCA,
  CDCA,
}

export const TipoLastroB3Dictionary: Record<TipoLastro, string> = {
  [TipoLastro.LCA]: 'LCA',
  [TipoLastro.CDCA]: 'CDCA',
};

export enum TipoLastroCERC {
  LCA,
  NAO = 3,
  SUBEX_PRONAF,
  SUBEX_PRONAMP,
  GERAL,
  POUPANCA_RURAL,
}

export const TipoLastroCERCDictionary: Record<TipoLastroCERC, string> = {
  [TipoLastroCERC.NAO]: 'Sem lastro',
  [TipoLastroCERC.SUBEX_PRONAF]: 'Subex Pronaf (MCR 6-2)',
  [TipoLastroCERC.SUBEX_PRONAMP]: 'Subex Pronamp (MCR 6-2)',
  [TipoLastroCERC.GERAL]: 'Geral (MCR 6-2)',
  [TipoLastroCERC.POUPANCA_RURAL]: 'Poupança Rural (MCR 6-4)',
  [TipoLastroCERC.LCA]: 'LCA (MCR 6-7)',
};


export const LastroDictionary: Record<TipoLastro , string> = {
  [TipoLastro.LCA]: 'LCA',
  [TipoLastro.CDCA]: 'CDCA',
};

export enum TipoMoeda  {
  EURO = 'EUR',
  DOLAR_AMERICANO = 'USD',
  REAL_BRASILEIRO = 'BRL'
}

export const MoedaDictionary: Record<TipoMoeda , string> = {
  [TipoMoeda.EURO]: 'Euro',
  [TipoMoeda.DOLAR_AMERICANO]: 'Dólar americano',
  [TipoMoeda.REAL_BRASILEIRO]: 'Real brasileiro',
};

export enum EnumTipoCalculo  {
  BRANCO,
  LINEAR,
}

export enum EnumTipoAtualizacaoTaxa  {
  BRANCO,
  D1,
  D2,
}

export const TipoAtualizacaoTaxaDictionary: Record<EnumTipoAtualizacaoTaxa | '' , string> = {
  ['']: 'Em branco',
  [EnumTipoAtualizacaoTaxa.BRANCO]: 'Em branco',
  [EnumTipoAtualizacaoTaxa.D1]: 'D - 1',
  [EnumTipoAtualizacaoTaxa.D2]: 'D - 2',
};

export enum EnumFonteInformacao  {
  BRANCO,
  SISBACEN,
}

export const FonteInformacaoDictionary: Record<EnumFonteInformacao | '' , string> = {
  ['']: 'Em branco',
  [EnumFonteInformacao.BRANCO]: 'Em branco',
  [EnumFonteInformacao.SISBACEN]: 'SISBACEN',
};

export enum EnumTipoIGPMIPCA  {
  ULTIMO_NUMERO_DISPONIVEL = 1,
  NUMERO_SEGUNDO_MES_ANTERIOR = 2
}

export const TipoIGPMIPCADictionary: Record<EnumTipoIGPMIPCA , string> = {
  [EnumTipoIGPMIPCA.ULTIMO_NUMERO_DISPONIVEL]: 'Último número disponível',
  [EnumTipoIGPMIPCA.NUMERO_SEGUNDO_MES_ANTERIOR]: 'Número do segundo mês anterior',
};

export enum EnumTipoFrequencia  {
  ANUAL_EMISSAO,
  ANUAL_VENCIMENTO
}

export const FrequenciaDictionary: Record<EnumTipoFrequencia , string> = {
  [EnumTipoFrequencia.ANUAL_EMISSAO]: 'ANUAL - Com base na data de vencimento',
  [EnumTipoFrequencia.ANUAL_VENCIMENTO]: 'ANUAL - Com base na data de emissão',
};
