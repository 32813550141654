<template>
  <cpr-modal-form
    :show-modal="showModal"
    :title="`${
      isEdit ? 'Editar' : 'Novo'
    } ${TipoProprietarioDictionary[formProprietario.tipoProprietario]}`"
    :formModel="formProprietario"
    nameCancel="Cancelar"
    refForm="formProprietario"
    :cancel="cancelForm"
    :submit="submitForm"
  >
    <cpr-section
      label="" 
      prop="dadosProprietario"
    >
      <cpr-radio-button
        v-model="formProprietario.tipoProprietario"
        :data="listTipoProprietario"
        label="Defina o tipo do cadastro"
      />
      <el-form-item
        prop="nome"
        label="Nome"
        :rules="$validationForm(['required'])"
      >
      <cpr-input v-model="formProprietario.nome" />
      </el-form-item>

      <el-form-item
        prop="cpf"
        label="CPF"
        :rules="$validationForm(['required', 'cpf'])"
      >
        <cpr-input
          v-model="formProprietario.cpf"
          v-mask="'###.###.###-##'"
          placeholder="000.000.000-00"
        />
      </el-form-item>

      <el-form-item prop="rg" label="RG">
        <cpr-input v-model="formProprietario.rg" placeholder="Digite o RG" />
      </el-form-item>
      <el-form-item prop="numero" label="Telefone">
        <cpr-input
          v-model="formProprietario.numero"
          v-mask="['(##)#####-####', '(##)####-####']"
          placeholder="(99)99999-9999"
        />
      </el-form-item>
    </cpr-section>
  </cpr-modal-form>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CprModalForm from '@/components/CprModalForm.vue';
import Input from '@/components/Input.vue';
import { IProprietarioModel } from '~/models/fazenda';
import { TipoProprietario, TipoProprietarioDictionary } from '~/Enums/tipoProprietario ';

@Component({
  components: {
    CprModalForm,
    'cpr-input': Input,
  },
})
export default class ModalFormProprietario extends Vue {
  @Prop() data!: IProprietarioModel;
  @Prop() showModal!: boolean;
  @Prop() index!: number;
  @Prop() toggleModalProprietario!: () => void;

  formProprietario = {
    tipoProprietario: TipoProprietario.PROPRIETARIO
  } as IProprietarioModel;

  get handleModal() {
    return this.showModal;
  }

  get listTipoProprietario() {
    return this.$dictionaryConvertion(TipoProprietarioDictionary);
  }

  get TipoProprietarioDictionary() {
    return TipoProprietarioDictionary
  }

  get isEdit(){
    return this.formProprietario.proprietarioId || this.formProprietario.id || (typeof this.index === 'number')
  }

  submitForm() {
    this.formProprietario.numero = this.$formatarTelefone(
      this.formProprietario.numero
    );
    this.$emit('submit',  { ...this.formProprietario, index: this.index });
    this.toggleModalProprietario();
  }

  cancelForm() {
    this.toggleModalProprietario();
    this.formProprietario = {} as IProprietarioModel;
  }

  @Watch('showModal')
  onShowModal(show: boolean) {
    if (show) {
      this.formProprietario = {
        tipoProprietario: TipoProprietario.PROPRIETARIO
      } as IProprietarioModel;
      
      this.formProprietario = this.data?.nome ? this.data as IProprietarioModel : this.formProprietario;

    } else {
      this.formProprietario = {
        tipoProprietario: TipoProprietario.PROPRIETARIO
      } as IProprietarioModel;
      this.$emit('cancel');
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  width: 8rem;
}

.margin-bottom {
  margin-bottom: 0px;
}
</style>